.color-default {
  color: rgba(24, 144, 255, 1);
}

.input-otp {
  width: 100% !important;
  height: 50px;
  border: 1px solid var(--light-gray);
  border-radius: 5px;
  font-size: 20px;
}

.input-otp:focus {
  outline: none;
  border: 1px solid rgba(24, 144, 255, 1);
  border-radius: 5px;
}

.input-otp::placeholder {
  color: var(--light-gray);
}

.input-otp-container {
  justify-content: center;
  gap: 10px;
}

.input__result {
  display: inline-block;
  margin: 10px auto;
  color: rgba(0, 0, 0, 0.45);
  &--count {
    color: black;
  }
}

.custom-phone-input {
  position: relative;
  display: block;
  margin-bottom: 8;
}

.phone-input-custom {
  margin-bottom: 24px;
  [class*="ant-form-item"] {
    margin-bottom: 0px !important;
  }
}
.custom-select {
  margin-bottom: 0;
  cursor: pointer;
  height: inherit;

  [class*="ant-select-selector"] {
    border: none !important;
    border-right: 1px solid #ababa1 !important;
    box-shadow: none !important;
    width: 60px !important;
  }
  [class*="prefix-icon-wrapper"] {
    width: fit-content;
  }

  [class="ant-select-selection-item"] {
    padding: 0px 0px !important;
    padding-top: 1px;
  }
}
