.group {
  background: transparent;

  [class*="ant-form-item-row"] {
    [class*="ant-form-item-label"] {
      text-align: left;
      width: 160px;
      label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.85);
        &::after {
          content: "";
        }
      }
    }
    [class*="ant-col ant-col-18 ant-form-item-control "] {
      flex-direction: row;
      flex-wrap: wrap;
      & > div {
        display: inline-flex;
        width: max-content;
      }
    }
  }
}
.upload {
  [class*="ant-upload-list-picture-card"] {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    &::before {
      content: unset;
    }
  }
  [class*="ant-upload-list-item"] {
    width: 200px !important;
    height: 128px !important;
    background-color: #ababb1;
    margin-block: 0 0px !important;
    margin-inline: 0 0px !important;
    border-radius: 16px !important;
    padding: 0 !important;
    border: none !important;
    &::before {
      display: none;
    }
    [class*="ant-upload-list-item-name"] {
      display: none !important;
    }

    [class*="ant-upload-list-item-actions"] {
      inset-inline-start: unset !important;
      top: 6px !important;
      right: 6px !important;
      width: 40px !important;
      height: 40px !important;
      background-color: transparent;
      background: linear-gradient(
        324deg,
        rgba(11, 11, 11, 0.8) 38.44%,
        rgba(22, 26, 24, 0.8) 85.8%
      );
      backdrop-filter: blur(12px);
      border-radius: 16px !important;
      opacity: 1 !important;
      overflow: hidden;

      [class*="ant-upload-list-item-action"] {
        background-color: transparent;
        width: 40px !important;
        height: 40px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1 !important;
      }
    }
  }
  [class*="ant-upload-select"] {
    background: transparent;
    width: 200px !important;
    height: 128px !important;
    margin-block: 0 !important;
    margin-inline: 0 !important;
    margin-bottom: 0px !important;
    color: rgba(0, 0, 0, 0.85);
    border: 1px dashed #4e4e54 !important;
    border-radius: 16px !important;
    cursor: pointer;
    &:hover {
      border-color: #469175;
      color: #469175;
    }
  }
}

.square {
  [class*="ant-upload-select"],
  [class*="ant-upload-list-item"] {
    width: 128px !important;
  }
}

.error {
  width: 75%;
  padding-left: 25%;
  margin-top: -20px;
  margin-bottom: 32px;
  [class*="ant-form-item-control-input"] {
    display: none;
  }
}

.description {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
}

.videoWrapper {
  position: relative;
  .thumbnail {
    width: 200px;
    height: 128px;
    object-fit: contain;
    border-radius: 16px;
  }
  .remove {
    position: absolute;
    top: 10px;
    right: 10px;
    color: rgba(255, 255, 255, 0.65);
    width: 16px;
    margin: 0 4px;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      color: #fff;
    }
  }
}
