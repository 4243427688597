@import "src/assets/scss/variable.scss";
@import "src/assets/scss/responsive.scss";

#layout .site-layout-background {
  background: $text-color-white;
}

#layout .logo {
  font-weight: 700;
  font-size: 22px;
  color: #ffffff;
  width: 232px;
  align-items: center;
  text-align: center;
  img {
    width: 180px;
    height: auto;
  }
}

#layout .other {
  width: calc(100%);
  background: #0f1f19;
  padding: 0px 30px;
}

#layout .ant-menu-submenu-selected {
  color: #054f31;
}

#layout .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e3f1ec;
  color: #054f31;
}

#layout .ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  border-right: 3px solid #054f31;
}

#layout .ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #054f31;
}

#layout
  .ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #054f31;
}

#layout .ant-layout.ant-layout-has-sider {
  min-height: calc(100vh - 64px);
}

#layout .ant-menu-title-content {
  text-transform: capitalize;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

#layout .breadcrumb {
  text-transform: capitalize;
  cursor: pointer;
}

#layout .ant-table-title {
  padding: 0px;
  border: none;
  margin-bottom: 16px;
}
#layout .header {
  display: flex;
  justify-content: center;
  padding: 0px;
  background: #ffffff;
  &_user-info {
    .ant-btn-text {
      color: #ffffff !important;
    }
    img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }
}

#layout .ant-layout-sider-trigger {
  background-color: #0f1f19 !important;
}

.noti {
  background-color: red;
  color: #fff;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 10px;

  margin-left: 10px;
}
