html {
  // font-size: 10px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// import fonts for root

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
    url(./assets/fonts/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
    url(./assets/fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
}

//scss modal
@import "./assets/scss/modal";

:root {
  --light-gray: #d9d9d9;
  --common-blue: #1890ff;
}
