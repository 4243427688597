.box {
  display: inline-block;
  width: max-content;
  height: max-content;
  margin-bottom: 0;
  label {
    height: auto !important;
  }
}

.label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 105px;
  padding: 3px;
  border: 1px solid #ececec;
  border-radius: 5px;
  margin-bottom: 0;
  cursor: pointer;
  &:hover {
    background-color: #ececec;
  }
}

.preview,
.over,
.placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  width: 100%;
  height: 100%;
  gap: 5px;
}

.preview {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.over {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000088;
  border-radius: 5px;
  color: white;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}
