.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex {
  display: flex;
}

.full-width {
  width: 100%;
}

.center {
  align-items: center;
}

.between {
  justify-content: space-between;
}

.ant-tabs-tabpane {
  outline: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

html *::-webkit-scrollbar {
  border-radius: 0px;
  width: 7px;
  height: 8px;
}
html *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--color_border_common);
}
html *::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: var(--color_background);
}

.antd-btn-nostyles {
  width: 100%;
  height: 100%;
  box-shadow: none;
  border: none;
  background: transparent !important;
  padding: 0px 0px;
  &:hover {
    color: #0f1f19;
  }
}
.antd-btn-nostyles-box {
  display: flex;
  gap: 10px;
}
