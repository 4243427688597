.create-update-category-modal {
  .ant-modal-body {
    padding-top: 30px;
  }
  .create-update-category-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }
  [class*="ant-input-suffix"] {
    position: absolute;
    right: 0;
    top: 31px;
  }
}
