.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}
.title-table {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
}

.cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
